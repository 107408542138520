import React, { useState, useEffect } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import loginImage1 from './login-image1.jpg';
import loginImage2 from './login-image2.jpg';
import loginImage3 from './login-image3.jpg';

const quotes = [
  "Welcome Back! Your Journey Starts Here – Log In and Elevate!",
  "Sign In, and Unleash What Awaits You!"
];

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  
  const navigate = useNavigate(); // Initialize useNavigate

  const images = [loginImage1, loginImage2, loginImage3];

  useEffect(() => {
    // Change image every 3 seconds
    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    // Change quote every 5 seconds
    const quoteInterval = setInterval(() => {
      setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 5000);

    // Clean up intervals on component unmount
    return () => {
      clearInterval(imageInterval);
      clearInterval(quoteInterval);
    };
  }, [images.length, quotes.length]);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('YOUR_LOGIN_API_ENDPOINT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Login successful
        console.log('Login successful:', data);
        navigate('/dashboard'); // Redirect to dashboard or homepage
      } else {
        // Handle login error
        setError(data.message || 'Invalid credentials. Please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('Something went wrong. Please try again later.');
    }
  };
  
  const handleHomeClick = () => {
    navigate('/'); // Navigate to the Home page
  };

  const handleSignUpClick = () => {
    navigate('/signup'); // Navigate to the Sign Up page
  };

  return (
    <div className="login-page">
      <div className="quote-container">
        <h2 className="quote">{quotes[currentQuoteIndex]}</h2>
      </div>
      <div className="login-container">
        <div className="login-form-container">
          <h2 className="login-title">Login</h2>
          <form className="login-form" onSubmit={handleLogin}>
            <label>Email:</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login-input"
            />
            <label>Password:</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
            {error && <p className="login-error">{error}</p>}
            <button type="submit" className="login-btn">Login</button>
          </form>
          <div className="buttons-container">
            <button onClick={handleHomeClick} className="home-btn">Home</button>
            <button onClick={handleSignUpClick} className="signup-btn">Sign Up</button>
          </div>
        </div>
        
        <div className="login-image-container">
          <img
            src={images[currentImageIndex]}
            alt="Login slideshow"
            className="login-image fade-in"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
