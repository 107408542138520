/**
 * Validates a name.
 * 
 * @param {string} name - The name to validate.
 * @returns {Object} - Validation result with 'valid' (boolean) and 'message' (string).
 * 
 * Rules:
 * - The name must contain only letters, spaces, hyphens, apostrophes, and periods.
 * - The name must not be empty or contain invalid sequences (e.g., double spaces, double hyphens).
 * - The length of the name must be between 2 and 50 characters.
 */
function validateName(name) {
    const regex = /^[a-zA-Z\s\-'.]+$/; // Allows valid characters for names.
    
    // Check if the name is empty or only whitespace.
    if (!name.trim()) {
        return { valid: false, message: "Name cannot be empty." };
    }
    
    // Check the length of the name.
    if (name.length < 2 || name.length > 50) {
        return { valid: false, message: "Name must be between 2 and 50 characters." };
    }
    
    // Check if the name contains invalid characters.
    if (!regex.test(name)) {
        return { valid: false, message: "Name contains invalid characters." };
    }
    
    // Check for invalid sequences like double spaces, double hyphens, or double periods.
    if (name.includes("  ") || name.includes("--") || name.includes("..")) {
        return {
            valid: false,
            message: "Name contains invalid sequences (e.g., double spaces, double hyphens)."
        };
    }
    
    // If all checks pass, the name is valid.
    return { valid: true, message: "Name is valid." };
}

/**
 * Validates an email address.
 * 
 * @param {string} email - The email to validate.
 * @returns {Object} - Validation result with 'valid' (boolean) and 'message' (string).
 * 
 * Rules:
 * - The email must follow the standard email format (e.g., user@domain.com).
 * - The length of the email must not exceed 100 characters.
 */
function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation.
    
    // Check if the email format is valid.
    if (!regex.test(email)) {
        return { valid: false, message: "Invalid email format." };
    }
    
    // Check if the email exceeds the maximum length.
    if (email.length > 100) {
        return { valid: false, message: "Email must not exceed 100 characters." };
    }
    
    // If all checks pass, the email is valid.
    return { valid: true, message: "Email is valid." };
}

/**
 * Validates a mobile number.
 * 
 * @param {string} mobile - The mobile number to validate.
 * @returns {Object} - Validation result with 'valid' (boolean) and 'message' (string).
 * 
 * Rules:
 * - The mobile number must follow the E.164 international format (e.g., +1234567890).
 * - The number must contain between 10 and 15 digits.
 * - The number must not contain any invalid characters.
 */
function validateMobileNumber(mobile) {
    const regex = /^\+?[1-9]\d{1,14}$/; // E.164 format for international numbers.
    
    // Check if the mobile number format is valid.
    if (!regex.test(mobile)) {
        return {
            valid: false,
            message: "Invalid mobile number format. Use E.164 format (e.g., +1234567890)."
        };
    }
    
    // Check the length of the mobile number.
    if (mobile.length < 10 || mobile.length > 15) {
        return {
            valid: false,
            message: "Mobile number must be between 10 and 15 digits."
        };
    }
    
    // If all checks pass, the mobile number is valid.
    return { valid: true, message: "Mobile number is valid." };
}


// Export the functions
export { validateName, validateEmail, validateMobileNumber };