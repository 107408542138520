import React, { useState } from 'react'
import '../css/sidebar.css'

const Sidebar = (props) => {

  return (
    <div className="container">

      <aside className={`sidebar ${props.isOpen ? "open" : ""}`}>

        <ul>

          <li>
            <a href="/dashboard">Dashboard</a>
          </li>
          <li>
            <a href="/profile">Profile</a>
          </li>
          <li>
            <a href="/findcandidates">Find Candidates</a>
          </li>
          <li>
            <a href="/resumedatabase">Resume Database</a>
          </li>
          <li>
            <a href="/candidateprofiles">Candidate Profiles </a>
          </li>

        <div className="logout-container">
          <button className="logout-button">
            <a href= "/logout" style={{ textDecoration: "none", color: "inherit"}}>
            Logout
            </a>
          </button>
        </div>

        </ul>
      </aside>


    </div>
  );
};

export default Sidebar
