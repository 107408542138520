import React, { useState, useEffect, useRef } from 'react';
import '../../css/chatbot.css';
import logo from '../../logo.jpg';
import { IconContext } from 'react-icons';
import { IoSend } from 'react-icons/io5';
import '../../validations/validations.js'
import { validateEmail, validateMobileNumber, validateName } from '../../validations/validations.js';

const Chatbot = () => {
    const [showChatsTab, setShowChatsTab] = useState(false); // if showChatsTab is false the chats will not display
    const [messages, setMessages] = useState([]); // All Talks done between user and chatbot is stored init
    const [isUsedChatBot, setIsUsedChatBot] = useState(false); // if Chatbot not used then small Talk by chatbot is not called
    const [userRegisterTalk, setUserRegisterTalk] = useState([]); // Talks for user Registration that will be fetched
    const [callToRegister, setCallToRegister] = useState(false); // This is calling for registration
    const [registeringData, setRegisteringData] = useState({ name: "", email: "", mobile_number: "" }); // Registering data will be updated here
    const [registeringDataStatus, setRegisteringDataStatus] = useState('inactive'); // Shows where the registration goes
    const [userInput, setUserInput] = useState(''); // Setting the current input by user
    const [submitting, setSubmitting] = useState(false); // State that shows submitting time by user
    const [options, setOptions] = useState([]); // Displaying different options that user can click and get answers accordingly

    const chatBoxRef = useRef(null); // useRef to scroll to bottom when new message arrives
    const inputRef = useRef(null); // useRef to focus on userInput when userInput submits
    // eslint-disable-next-line
    const [APIEndPoint, setAPIEndPoint] = useState('https://api.codevocado.in');

    // When new message arrives, scroll to bottom and focus on userInput input
    useEffect(() => {
        if (chatBoxRef.current) chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        if (inputRef.current) inputRef.current.focus();
    }, [messages]);

    // handle when Options be clicked
    const handleOptionsClick = (e) => {
        setUserInput(e.target.innerText);
    };

    // handle when userInput input is changed by writing
    const handleUserInputChange = (e) => {
        setUserInput(e.target.value);
    };

    // Getting url and formData to POST Req to given url and getting data from that point
    const fetchPOSTReq = async (url, formData = '', ifFailed = { error: 'Internal Server Error.' }) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            return data;
        } catch (error) {
            return ifFailed;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (userInput.length === 0) { // userInput should not be an empty string
                setMessages(prevMessages => [...prevMessages, { bot: 'Your input is not defined.' }]);
                return false;
            }
            setSubmitting(true);

            // Prepare userInput form data
            const formData = { question: userInput };
            setMessages(prevMessages => [...prevMessages, { user: userInput }]);

            // callToRegister is false and userInput is request a demo then start registering User
            if (userInput.toLowerCase().includes('request a demo') && !callToRegister) {
                setRegisteringDataStatus('activate');
                setRegisteringData({ name: "", email: "", mobile_number: "" });
                setMessages(prevMessages => [
                    ...prevMessages, { bot: userRegisterTalk.ChatbotAsk !== "Error connecting to network." ? JSON.stringify(userRegisterTalk['ChatbotAsk']) : userRegisterTalk.ChatbotAsk }
                ]);
                setCallToRegister(true);
            }

            const isUserRegisterComplete = userRegisterManager();

            if (!isUserRegisterComplete || userInput.toLowerCase().includes('request a demo')) {
                setSubmitting(false);
                setUserInput('')
                return 0;
            }
            // Clear the userInput input
            setUserInput('')


            // First fetch for asking the userInput
            const data = await fetchPOSTReq(`${APIEndPoint}/api/chatbot/ask`, formData, { answer: [{ answer: "Error connecting to network." }] })
            setMessages(prevMessages => [
                ...prevMessages,
                { bot: data.answer ? data.answer[0].answer : data.answer[0].answer }
            ]);
            setSubmitting(false);
        } catch (error) {
            console.log(error);
            setSubmitting(false); // Ensuring that fetching is set to false in case of error
        }
        finally {
            setSubmitting(false); // Ensuring that fetching is set to false in case of error
            setUserInput('')
        }
    };

    // Helper function to update messages and registration status
    const updateStep = (newStatus, message, setUserInputValue = null) => {
        setMessages(prevMessages => [
            ...prevMessages,
            { bot: message + (setUserInputValue !== null && setUserInputValue !== "" ? `: ${setUserInputValue}` : "") }
        ]);
        if (setUserInputValue !== null) setUserInput(setUserInputValue);
        setRegisteringDataStatus(newStatus);
    };

    // Steps to handle user registration process
    const stepHandlers = {
        activate: () => {
            if (userInput.toLowerCase() === 'agree' || userInput.toLowerCase().includes('y')) {
                updateStep('name', userRegisterTalk.ifAgreed);
                updateStep('name', userRegisterTalk['Questions'][0]);
            } else {
                updateStep(null, userRegisterTalk.notAgreed);
                setRegisteringDataStatus('inactive');
                setCallToRegister(false);
            }
        },
        name: () => {
            if (!validateName(userInput)['valid']) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { bot: 'Please enter a your correct Name' }
                ]);
                return 1;
            }
            registeringData.name = userInput;
            updateStep('email', userRegisterTalk['Questions'][1], registeringData.email);
        },
        email: () => {
            if (!validateEmail(userInput)['valid']) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { bot: 'Please enter a correct Email Address' }
                ]);
                return 1;
            }
            registeringData.email = userInput;
            updateStep('mobile_number', userRegisterTalk['Questions'][2], registeringData.mobile_number);
        },
        mobile_number: () => {
            if (!validateMobileNumber(userInput)['valid']) {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { bot: 'Please enter a correct Mobile Number' }
                ]);
                return 1;
            }
            registeringData.mobile_number = userInput;
            updateStep('isDataCorrect', `Your full name is: ${registeringData.name}.\nYour email is: ${registeringData.email}.\nAnd your mobile number is: ${registeringData.mobile_number}.\nPlease write 'yes' to save or 'no' to edit your credentials.`);
            setCallToRegister(true);
        },
        isDataCorrect: async () => {
            if (userInput.toLowerCase().includes('y') || userInput.toLowerCase() === 'agree') {
                // Logic to save to the database
                setRegisteringDataStatus('adding');
                setMessages(prevMessages => [
                    ...prevMessages,
                    { bot: 'Saving Your Credentials...' }
                ]);
                const data = await fetchPOSTReq(`${APIEndPoint}/api/chatbot/register`, registeringData, ['Internal Server Error.'])
                setMessages(prevMessages => [
                    ...prevMessages,
                    { bot: data[0] }
                ]);
                setRegisteringDataStatus('inactive');
                setCallToRegister(false);
            } else if (userInput.toLowerCase().includes('n')) {
                // Reset to name to start correction    
                updateStep('name', `Let's start from the beginning. Please confirm your name`, registeringData.name);
            }
        }
    };

    // function used to help things done with stepHandlers and done email registration well
    const userRegisterManager = () => {
        if (!callToRegister) return 1;
        const handleStep = stepHandlers[registeringDataStatus];
        if (handleStep) handleStep();
        return 0;
    };

    // handle when chat be started do certain things before starting the chat like smallTalk
    const handleStartChatSubmit = async (e) => {
        e.preventDefault();
        if (isUsedChatBot) return true;
        try {
            setSubmitting(true);
            // chat starting talk
            const data = await fetchPOSTReq(`${APIEndPoint}/api/chatbot/start`, '', [['Welcome to the ATS support chat! Error connecting to network.'], []])
            setMessages(prevMessages => [...prevMessages, { bot: data[0][0] }]);
            setOptions(!data.error ? data[1] : data)
            setSubmitting(false);

            // fetch for email registration talks             
            const dataEmailRes = await fetchPOSTReq(`${APIEndPoint}/api/chatbot/askregister`, '', { ChatbotAsk: "Error connecting to network." })
            setUserRegisterTalk(dataEmailRes);

            setIsUsedChatBot(true)
        } catch (error) {
            console.log('Error:', error);
            setSubmitting(false);  // Ensuring that fetching is set to false in case of error
        }
    };

    return (
        <>
            {showChatsTab && (
                <div className="chat-box">
                    <div className="chat-nav">ATS Chatbot</div>
                    <div className="inner-chat-box chats" ref={chatBoxRef}>
                        {messages.map((msg, index) => (
                            <div className={msg.user ? "user right" : "chat-bot left"} key={index}>
                                {msg.user || msg.bot}
                            </div>
                        ))}
                        {submitting && <div className={"chat-bot left"}>
                            Loading...
                        </div>}
                        {options.length !== 0 && <form className='chat-form-options' onSubmit={handleSubmit}>
                            <div className="options">
                                {options.map((option, index) => (
                                    <button type="submit" className="left border-1 Questions" key={index} onClick={(e) => handleOptionsClick(e)}>
                                        {option}
                                    </button>
                                ))}
                            </div>
                        </form>}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input type="text" onChange={handleUserInputChange} name="userInput" placeholder="Enter your Ask" value={userInput} ref={inputRef} />
                        <IconContext.Provider value={{ size: '18px' }}>
                            <button type="submit"><IoSend /></button>
                        </IconContext.Provider>
                    </form>
                </div>
            )}
            <div className="start-chat-btn" onClick={() => setShowChatsTab(!showChatsTab)}>
                <form onSubmit={handleStartChatSubmit}>
                    <button>
                        <span>Ask</span>
                        <img src={logo} width={40} height={40} alt="Logo" />
                    </button>
                </form>
            </div>
        </>
    );
};

export default Chatbot;