import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signup.css';
import signupImage1 from './signupImage1.jpg';
import signupImage2 from './signupImage2.jpg';
import signupImage3 from './signupImage3.jpg';

const quotes = [
  "Welcome Aboard! Create an Account to Begin!",
  "Join Us, and Unlock Your Potential!"
];

const SignUp = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState(''); // For error messages
  const navigate = useNavigate();

  const images = [signupImage1, signupImage2, signupImage3];

  // Handling the image and quote transitions using useEffect
  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    const quoteInterval = setInterval(() => {
      setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 5000);

    return () => {
      clearInterval(imageInterval);
      clearInterval(quoteInterval);
    };
  }, [images.length]);

  // Handling form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission and signup API request
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const response = await fetch('http://localhost:5000/api/signup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: formData.username,
            email: formData.email,
            password: formData.password,
        }),
      });

      const data = await response.json();

      // Check for successful response
      if (response.ok) {
        console.log('Signup successful:', data);
        navigate('/login'); // Redirect to login page on successful signup
      } else {
        setErrorMessage(data.message || 'Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      alert('Something went wrong. Please try again later.');
    }
  };

  // Handle redirect to login page
  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <div className="signup-page">
      <div className="quote-container">
        <h2 className="quote">{quotes[currentQuoteIndex]}</h2>
      </div>
      <div className="signup-container">
        <div className="signup-form-container">
          <h2 className="signup-title">Sign Up</h2>
          <form onSubmit={handleSubmit}>
            <label>Username:</label>
            <input
              type="text"
              name="username"
              placeholder="Enter your username"
              value={formData.username}
              onChange={handleChange}
              required
              className="signup-input"
            />
            <label>Email:</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
              className="signup-input"
            />
            <label>Password:</label>
            <input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
              className="signup-input"
            />
            <label>Confirm Password:</label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm your password"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              className="signup-input"
            />
            <button type="submit" className="signup-button">Sign Up</button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
          <div className="login-redirect">
            <p>Already have an account?</p>
            <button onClick={handleLoginRedirect} className="login-switch-button">Go to Login</button>
          </div>
        </div>
        <div className="signup-image-container">
          <img
            src={images[currentImageIndex]}
            alt="Signup slideshow"
            className="signup-image fade-in"
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
