import React, { useState } from 'react'
import Sidebar from './sidebar.js'

// Navbar Component
const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    return (<>
        
            <nav className="navbar">
                <button className="menu-button" onClick={toggleSidebar}>
                    ☰
                </button>

                <h1>Codevocado ATS</h1>
                <ul>
                    <li><a href="#features">Features</a></li>
                    <li><a href="#pricing">Pricing</a></li>
                    <li><a href="#testimonials">Testimonials</a></li>
                </ul>
                <div className="auth-buttons">
                    <button onClick={() => window.location.href = '/login'}>Log In</button>
                    <button onClick={() => window.location.href = '/signup'}>Sign Up</button>
                </div>
            </nav>
        
        <Sidebar isOpen={isOpen} />
    </>

    );
};

export default Navbar